document.addEventListener('DOMContentLoaded', _ => {
    const passwordStrengthEls = document.querySelectorAll('.js-passwordStrength');

    let tokenInputs = document.querySelectorAll('.js-loginToken input');

    (async _ => {
        const IMask = (await import('imask/esm/imask')).default;
        await import('imask/esm/masked/dynamic');

        const emailAddressEl = document.getElementById('email_address');
        const emailRecoveryEl = document.getElementById('email_address_recover');

        if (emailAddressEl) {
            const opts = emailAddressEl.dataset?.totem
                ? {mask: '000.000.000-00'}
                : {
                    mask: [
                        {
                            mask: '000.000.000-00',
                        },
                        {
                            mask: 'w@w.w',
                            blocks: {
                                w: {mask: /\w*/g}
                            }
                        }
                    ]
                };
            IMask(emailAddressEl, opts);
        }

        if (emailRecoveryEl) {
            IMask(emailRecoveryEl, {
                    mask: [
                        {
                            mask: '000.000.000-00',
                        },
                        {
                            mask: 'w@w.w',
                            blocks: {
                                w: {mask: /\w*/g}
                            }
                        }
                    ]
                }
            );
        }

        tokenInputs?.forEach(el => IMask(el, {mask: '0'}));
    })();

    if (passwordStrengthEls.length > 0) {
        (async _ => {
            const passwordStrength = (await import('./js/components/passwordStrength')).default;

            new passwordStrength({
                inputs: passwordStrengthEls
            });
        })();
    }

    console.clear();
    const countDown = $('[data-countdown]');
    if (countDown.length > 0) {
        const finalDate = countDown.data('countdown');

        console.log(finalDate)

        countDown
            .countdown(finalDate, e => {
                countDown.html(`&nbsp;(${e.strftime('%M:%S')})`);
                if ('finish' === e.type) {
                    tokenInputs.forEach(el => {
                        el.value = '';
                        el.disabled = true;
                    });
                    countDown.html('');
                    document.getElementById('validate').disabled = true;
                    document.getElementById('resend').disabled = false;
                }
            })
            .on('finish.countdown', e => {
                console.log(e)

                tokenInputs.forEach(el => {
                    console.log(el)
                    el.value = '';
                    el.disabled = true;
                });
                countDown.html('');
                document.getElementById('validate').disabled = true;
                document.getElementById('resend').disabled = false;
            })
        ;
    }

    const handlePaste = function (e) {
        if ('input' !== e.target.localName) {
            return;
        }
        if (0 === tokenInputs.length) {
            return;
        }
        e.preventDefault();

        let paste = (e.clipboardData || window.clipboardData)?.getData('text') || e.data;
        paste = paste.replace(/\D/, '');
        if (paste.length !== tokenInputs.length) {
            return;
        }
        tokenInputs.forEach((input, index) => {
            input.focus();
            input.value = paste[index];
        });
        $('#validate').focus();
    };
    document.addEventListener('paste', e => handlePaste(e));
    tokenInputs?.forEach((input, index, arr) => {
        input.addEventListener('keydown', e => {
            if ('Backspace' === e.key) {
                e.target.value = '';
                arr[index - 1]?.focus();
                e.preventDefault();
            }
        });
        input.addEventListener('input', function (e) {
            switch (true) {
                case ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.data):
                    arr[index + 1]?.focus();
                    if (tokenInputs.length === index + 1) {
                        document.getElementById('validate')?.focus();
                    }
                    break;

                case e.data?.length === tokenInputs.length:
                    handlePaste(e);
                    break;

                default:
                    this.value = '';
                    break;
            }
        });
    });
    tokenInputs[0]?.focus();
});
